.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.brand-logo {
      padding-left: 15px;
    font-size: 3em;
    text-decoration: none;
    font-weight: 900;
    font-style: italic;
    color: cadetblue;
}

body {
  background-color: #282c34;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*.nav-style {*/
/*      display: flex;*/
/*    align-items: stretch; !* Default *!*/
/*    justify-content: space-between;*/
/*    width: 100%;*/
/*    background: #cacaca;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/


#nav-mobile {

  display: flex;
  width: 100%;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav-wrapper {

}

li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    background: #fafafa;
  padding: 5px;
}

li a {
  font-family: 'Lato', Calibri, Arial, sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


section {
  position: relative;
  height: 130vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

section h1 {
    font-size: 2rem;
        color: aliceblue;
}

.subpoint {
    font-weight: 800;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container {
      margin: 100px;
    padding: 100px;
    background-color: whitesmoke;
}

.resources {
  display: flex;
  flex-direction: column;
}
